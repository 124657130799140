// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Montserrat", sans-serif;
  /** primary **/
  --ion-color-primary: #046421;
  --ion-color-primary-rgb: 4, 100, 33;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #04581d;
  --ion-color-primary-tint: #1d7437;
  /** secondary **/
  --ion-color-secondary: #009000;
  --ion-color-secondary-rgb: 0, 144, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #007f00;
  --ion-color-secondary-tint: #1a9b1a;
  /** secondary light **/
  --ion-color-secondary-light: #80c880;
  --ion-color-secondary-light-rgb: 128, 200, 128;
  --ion-color-secondary-light-contrast: #000000;
  --ion-color-secondary-light-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-light-shade: #71b071;
  --ion-color-secondary-light-tint: #8dce8d;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;
  /*** white ***/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
  /*** 3e3e3e ***/
  --ion-color-3e3e3e: #3e3e3e;
  --ion-color-3e3e3e-rgb: 62, 62, 62;
  --ion-color-3e3e3e-contrast: #ffffff;
  --ion-color-3e3e3e-contrast-rgb: 255, 255, 255;
  --ion-color-3e3e3e-shade: #373737;
  --ion-color-3e3e3e-tint: #515151;
  /*** bfbfbf ***/
  --ion-color-bfbfbf: #bfbfbf;
  --ion-color-bfbfbf-rgb: 191, 191, 191;
  --ion-color-bfbfbf-contrast: #000000;
  --ion-color-bfbfbf-contrast-rgb: 0, 0, 0;
  --ion-color-bfbfbf-shade: #a8a8a8;
  --ion-color-bfbfbf-tint: #c5c5c5;
  /*** 636363 ***/
  --ion-color-636363: #636363;
  --ion-color-636363-rgb: 99, 99, 99;
  --ion-color-636363-contrast: #ffffff;
  --ion-color-636363-contrast-rgb: 255, 255, 255;
  --ion-color-636363-shade: #575757;
  --ion-color-636363-tint: #737373;
  /*** 222222 ***/
  --ion-color-222222: #222222;
  --ion-color-222222-rgb: 34, 34, 34;
  --ion-color-222222-contrast: #ffffff;
  --ion-color-222222-contrast-rgb: 255, 255, 255;
  --ion-color-222222-shade: #1e1e1e;
  --ion-color-222222-tint: #383838;
  /*** 686868 ***/
  --ion-color-686868: #686868;
  --ion-color-686868-rgb: 104, 104, 104;
  --ion-color-686868-contrast: #ffffff;
  --ion-color-686868-contrast-rgb: 255, 255, 255;
  --ion-color-686868-shade: #5c5c5c;
  --ion-color-686868-tint: #777777;
  /*** 252525 ***/
  --ion-color-252525: #252525;
  --ion-color-252525-rgb: 37, 37, 37;
  --ion-color-252525-contrast: #ffffff;
  --ion-color-252525-contrast-rgb: 255, 255, 255;
  --ion-color-252525-shade: #212121;
  --ion-color-252525-tint: #3b3b3b;
  /*** 9a9a9c ***/
  --ion-color-9a9a9c: #9a9a9c;
  --ion-color-9a9a9c-rgb: 154, 154, 156;
  --ion-color-9a9a9c-contrast: #000000;
  --ion-color-9a9a9c-contrast-rgb: 0, 0, 0;
  --ion-color-9a9a9c-shade: #888889;
  --ion-color-9a9a9c-tint: #a4a4a6;
  /*** 2a2927 ***/
  --ion-color-2a2927: #2a2927;
  --ion-color-2a2927-rgb: 42, 41, 39;
  --ion-color-2a2927-contrast: #ffffff;
  --ion-color-2a2927-contrast-rgb: 255, 255, 255;
  --ion-color-2a2927-shade: #252422;
  --ion-color-2a2927-tint: #3f3e3d;
  /*** 808080 ***/
  --ion-color-808080: #808080;
  --ion-color-808080-rgb: 128, 128, 128;
  --ion-color-808080-contrast: #000000;
  --ion-color-808080-contrast-rgb: 0, 0, 0;
  --ion-color-808080-shade: #717171;
  --ion-color-808080-tint: #8d8d8d;
  /*** b5b5b5 ***/
  --ion-color-b5b5b5: #b5b5b5;
  --ion-color-b5b5b5-rgb: 181, 181, 181;
  --ion-color-b5b5b5-contrast: #000000;
  --ion-color-b5b5b5-contrast-rgb: 0, 0, 0;
  --ion-color-b5b5b5-shade: #9f9f9f;
  --ion-color-b5b5b5-tint: #bcbcbc;
  /*** 131313 ***/
  --ion-color-131313: #131313;
  --ion-color-131313-rgb: 19, 19, 19;
  --ion-color-131313-contrast: #ffffff;
  --ion-color-131313-contrast-rgb: 255, 255, 255;
  --ion-color-131313-shade: #111111;
  --ion-color-131313-tint: #2b2b2b;
  /*** 727272 ***/
  --ion-color-727272: #727272;
  --ion-color-727272-rgb: 114, 114, 114;
  --ion-color-727272-contrast: #ffffff;
  --ion-color-727272-contrast-rgb: 255, 255, 255;
  --ion-color-727272-shade: #646464;
  --ion-color-727272-tint: #808080;
  /*** 555555 ***/
  --ion-color-555555: #555555;
  --ion-color-555555-rgb: 85, 85, 85;
  --ion-color-555555-contrast: #ffffff;
  --ion-color-555555-contrast-rgb: 255, 255, 255;
  --ion-color-555555-shade: #4b4b4b;
  --ion-color-555555-tint: #666666;
  /*** 00ff00 ***/
  --ion-color-00ff00: #00ff00;
  --ion-color-00ff00-rgb: 0, 255, 0;
  --ion-color-00ff00-contrast: #000000;
  --ion-color-00ff00-contrast-rgb: 0, 0, 0;
  --ion-color-00ff00-shade: #00e000;
  --ion-color-00ff00-tint: #1aff1a;
  /*** C5FFC9 ***/
  --ion-color-c5ffc9: #c5ffc9;
  --ion-color-c5ffc9-rgb: 197, 255, 201;
  --ion-color-c5ffc9-contrast: #000000;
  --ion-color-c5ffc9-contrast-rgb: 0, 0, 0;
  --ion-color-c5ffc9-shade: #ade0b1;
  --ion-color-c5ffc9-tint: #cbffce;
  /*** #243e24 ***/
  --ion-color-243e24: #243e24;
  --ion-color-243e24-rgb: 36, 62, 36;
  --ion-color-243e24-contrast: #ffffff;
  --ion-color-243e24-contrast-rgb: 255, 255, 255;
  --ion-color-243e24-shade: #203720;
  --ion-color-243e24-tint: #3a513a;
  /*** #769979 ***/
  --ion-color-769979: #769979;
  --ion-color-769979-rgb: 118, 153, 121;
  --ion-color-769979-contrast: #000000;
  --ion-color-769979-contrast-rgb: 0, 0, 0;
  --ion-color-769979-shade: #68876a;
  --ion-color-769979-tint: #84a386;
  /*** #C4C4C4 ***/
  --ion-color-c4c4c4: #c4c4c4;
  --ion-color-c4c4c4-rgb: 196, 196, 196;
  --ion-color-c4c4c4-contrast: #000000;
  --ion-color-c4c4c4-contrast-rgb: 0, 0, 0;
  --ion-color-c4c4c4-shade: #acacac;
  --ion-color-c4c4c4-tint: #cacaca;
  /*** #ababab ***/
  --ion-color-ababab: #ababab;
  --ion-color-ababab-rgb: 171, 171, 171;
  --ion-color-ababab-contrast: #000000;
  --ion-color-ababab-contrast-rgb: 0, 0, 0;
  --ion-color-ababab-shade: #969696;
  --ion-color-ababab-tint: #b3b3b3;
  /*** #9d9d9d ***/
  --ion-color-9d9d9d: #9d9d9d;
  --ion-color-9d9d9d-rgb: 157, 157, 157;
  --ion-color-9d9d9d-contrast: #000000;
  --ion-color-9d9d9d-contrast-rgb: 0, 0, 0;
  --ion-color-9d9d9d-shade: #8a8a8a;
  --ion-color-9d9d9d-tint: #a7a7a7;
  /*** #5a5a5a ***/
  --ion-color-5a5a5a: #5a5a5a;
  --ion-color-5a5a5a-rgb: 90, 90, 90;
  --ion-color-5a5a5a-contrast: #ffffff;
  --ion-color-5a5a5a-contrast-rgb: 255, 255, 255;
  --ion-color-5a5a5a-shade: #4f4f4f;
  --ion-color-5a5a5a-tint: #6b6b6b;
}
