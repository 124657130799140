/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
//** Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
//** Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
body {
  background-color: var(--ion-color-black);
}
body,
html {
  width: 100%;
  height: 100%;
}
body,
html,
* {
  font-family: "Montserrat", sans-serif !important;
  --ion-default-font: "Montserrat", sans-serif !important;
}
* {
  // scrollbar-width: thin;
  // scrollbar-color: var(--ion-color-primary);
  -webkit-tap-highlight-color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--ion-color-black);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-9a9a9c);
  border-radius: 10px;
}
p,
ul {
  margin: 0;
  padding: 0;
}
//**  ==================================== Utility Classes for Text Starts
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
.fs-0 {
  font-size: 0;
}
.fs-4 {
  font-size: 4px;
}
.fs-6 {
  font-size: 6px;
}
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px;
}
.fs-32 {
  font-size: 32px;
}
.fs-36 {
  font-size: 36px;
}
.fs-40 {
  font-size: 40px;
}
.fs-48 {
  font-size: 48px;
}
.fs-70 {
  font-size: 70px;
}
.ls-0 {
  letter-spacing: 0;
}
.ls-2 {
  letter-spacing: 0.2px;
}
.ls-4 {
  letter-spacing: 0.4px;
}
.ls-6 {
  letter-spacing: 0.6px;
}
.ls-8 {
  letter-spacing: 0.8px;
}
.ls-10 {
  letter-spacing: 1px;
}
.lh-1 {
  line-height: 1;
}
.lh-12 {
  line-height: 1.2;
}
.lh-15 {
  line-height: 1.5;
}
.lh-20 {
  line-height: 2;
}
.fs-italic {
  font-style: italic;
}
//**  ==================================== Utility Classes for Text Ends
//**  ==================================== Utility Classes Starts
.one3d-bg-white {
  background-color: var(--ion-color-white);
}
.one3d-bg-black {
  background-color: var(--ion-color-black);
}
.one3d-bg-primary {
  background-color: var(--ion-color-primary);
}
.one3d-bg-error {
  background-color: var(--ion-color-error);
}
.one3d-bg-686868 {
  background-color: var(--ion-color-686868);
}
.one3d-underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.one3d-underline-none {
  text-decoration: none;
}
.one3d-transition-2 {
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  transition: 0.2s all ease;
}
.one3d-transition-4 {
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  transition: 0.4s all ease;
}
.one3d-transition-6 {
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  transition: 0.6s all ease;
}
.one3d-transition-8 {
  -webkit-transition: 0.8s all ease;
  -moz-transition: 0.8s all ease;
  -ms-transition: 0.8s all ease;
  transition: 0.8s all ease;
}
.one3d-translate-x {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.one3d-translate-y {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.one3d-translate-xy {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.one3d-blur-4 {
  -webkit-backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
  -ms-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
.one3d-blur-8 {
  -webkit-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  -ms-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.one3d-blur-12 {
  -webkit-backdrop-filter: blur(12px);
  -moz-backdrop-filter: blur(12px);
  -ms-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
}
.one3d-left-0 {
  left: 0;
}
.one3d-top-0 {
  top: 0;
}
.one3d-right-0 {
  right: 0;
}
.one3d-bottom-0 {
  bottom: 0;
}
.one3d-left-50 {
  left: 50%;
}
.one3d-top-50 {
  top: 50%;
}
.one3d-events-none {
  pointer-events: none;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-10 {
  z-index: 10;
}
.z-100 {
  z-index: 100;
}
.z-1000 {
  z-index: 1000;
}
.z-10000 {
  z-index: 10000;
}
//**  ====================================  Utility Classes Ends
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-none {
  display: none;
}
.opacity-4 {
  opacity: 0.4;
}
.o-hidden {
  opacity: 0;
  visibility: hidden;
}
.o-visible {
  opacity: 1;
  visibility: visible;
}
.flex-auto-only {
  flex: auto;
}
.flex-auto {
  flex: 0 0 auto;
}
.fd-row {
  flex-direction: row;
}
.fd-column {
  flex-direction: column;
}
.of-contain {
  object-fit: contain;
}
.w-max {
  width: max-content;
}
.w-50 {
  width: 50%;
}
.h-50 {
  height: 50%;
}
.vw-100 {
  width: 100vw;
}
.vh-100 {
  height: 100vh;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.h-98 {
  height: 98%;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.one3d-overflow-hidden {
  overflow: hidden;
}
.one3d-pointer {
  cursor: pointer;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.pl-12 {
  padding-left: 12px;
}
.pl-16 {
  padding-left: 16px;
}
.p-12 {
  padding: 12px;
}
.p-20 {
  padding: 20px;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.one3d-select-none {
  user-select: none;
}
.one3d-list-none {
  list-style: none;
}
.one3d-tt-none {
  text-transform: none;
}
.ws-nowrap {
  white-space: nowrap;
}
.color-black {
  color: var(--ion-color-black);
}
.color-white {
  color: var(--ion-color-white);
}
.color-primary {
  color: var(--ion-color-primary);
}
.color-secondary {
  color: var(--ion-color-secondary);
}
.color-secondary-light {
  color: var(--ion-color-secondary-light);
}
.color-success {
  color: var(--ion-color-success);
}
.color-warning {
  color: var(--ion-color-warning);
}
.color-danger {
  color: var(--ion-color-danger);
}
.color-3e3e3e {
  color: var(--ion-color-3e3e3e);
}
.color-c4c4c4 {
  color: var(--ion-color-c4c4c4);
}
.color-bfbfbf {
  color: var(--ion-color-bfbfbf);
}
.color-636363 {
  color: var(--ion-color-636363);
}
.color-222222 {
  color: var(--ion-color-222222);
}
.color-555555 {
  color: var(--ion-color-555555);
}
.color-686868 {
  color: var(--ion-color-686868);
}
.color-252525 {
  color: var(--ion-color-252525);
}
.color-9a9a9c {
  color: var(--ion-color-9a9a9c);
}
.color-2a2927 {
  color: var(--ion-color-2a2927);
}
.color-808080 {
  color: var(--ion-color-808080);
}
.color-b5b5b5 {
  color: var(--ion-color-b5b5b5);
}
.color-727272 {
  color: var(--ion-color-727272);
}
.color-c5ffc9 {
  color: var(--ion-color-c5ffc9);
}
.color-00ff00 {
  color: var(--ion-color-00ff00);
}
.color-769979 {
  color: var(--ion-color-769979);
}
.color-off-white {
  color: rgba(var(--ion-color-white-rgb), 0.3);
}
.color-light-white {
  color: rgba(var(--ion-color-white-rgb), 0.8);
}
.color-ababab {
  color: var(--ion-color-ababab);
}
.color-9d9d9d {
  color: var(--ion-color-9d9d9d);
}
.one3d-bg-222222 {
  background-color: var(--ion-color-222222);
}
.one3d-brand-header {
  width: 100%;
}
.one3d-brand-header-img {
  height: 72px;
  z-index: 2;
}
.one3d-opacity-0 {
  opacity: 0;
}
.one3d-opacity-05 {
  opacity: 0.5;
}
.one3d-v-hidden {
  visibility: hidden;
}
.no-scroll {
  --overflow: hidden;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
//***  ==================================== visualizer container starts here
.one3d-visualizer-container {
  // height: calc(100% - 72px);
  height: 100%;
}
.one3d-brand-logo-left-corner {
  left: 60px;
  top: 20px;
}
.one3d-brand-name-logo-center {
  top: 60px;
}
//***  ==================================== visualizer container ends here
//***  ==================================== test drive speaker starts here
.one3d-test-drive-speaker-box {
  left: 20px;
  bottom: 20px;
}
.one3d-button-primary {
  padding: 2px;
  -webkit-clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    9.4% 100%,
    0% 72.5%
  );
  clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    9.4% 100%,
    0% 72.5%
  );
  background: linear-gradient(176.35deg, #009000 23.08%, #243e24 114.41%);
}
.one3d-button-primary-text {
  padding: 5px 10px;
  background: var(--ion-color-primary);
  -webkit-clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    9.4% 100%,
    0% 72.5%
  );
  clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    9.4% 100%,
    0% 72.5%
  );
}
.one3d-button-secondary {
  padding: 2px;
  margin: 0px 10px;
  // -webkit-clip-path: polygon(
  //   0% 0%,
  //   90.8% 0%,
  //   100.1% 0%,
  //   100.1% 99.5%,
  //   9.4% 100%,
  //   0% 72.5%
  // );
  // clip-path: polygon(
  //   0% 0%,
  //   90.8% 0%,
  //   100.1% 0%,
  //   100.1% 99.5%,
  //   9.4% 100%,
  //   0% 72.5%
  // );
  -webkit-clip-path: polygon(100% 1%, 100% 70%, 92% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 1%, 100% 70%, 92% 100%, 0 100%, 0 0);
  background: linear-gradient(176.35deg, #fff 23.08%, #555 114.41%);
}
.one3d-button-secondary-text {
  padding: 5px 10px;
  background: var(--ion-color-black);
  -webkit-clip-path: polygon(100% 1%, 100% 70%, 92% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 1%, 100% 70%, 92% 100%, 0 100%, 0 0);
}
.one3d-button-tertiary {
  padding: 2px;
  -webkit-clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    0% 100%,
    0% 72.5%
  );
  clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    0% 100%,
    0% 72.5%
  );
  background: linear-gradient(176.35deg, #009000 23.08%, #243e24 114.41%);
}
.one3d-button-tertiary-text {
  padding: 5px 10px;
  background: var(--ion-color-243e24);
  -webkit-clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    0% 100%,
    0% 72.5%
  );
  clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 28.5%,
    100.1% 99.5%,
    0% 100%,
    0% 72.5%
  );
}
#backToEmaginatorButton {
  -webkit-clip-path: polygon(100% 1%, 100% 100%, 12% 100%, 0 69%, 0 0);
  clip-path: polygon(100% 1%, 100% 100%, 12% 100%, 0 69%, 0 0);
  .one3d-button-primary-text {
    -webkit-clip-path: polygon(100% 1%, 100% 100%, 12% 100%, 0 69%, 0 0);
    clip-path: polygon(100% 1%, 100% 100%, 12% 100%, 0 69%, 0 0);
  }
}
.one3d-sound-button-contain {
  margin-left: 10px;
}
//***  ==================================== test drive speaker ends here
//***  ==================================== description and privacy starts here
.one3d-description {
  left: 0px;
  bottom: 1px;
  width: 100%;
  ion-text {
    width: 100%;
    display: block;
    text-align: left;
    position: relative;
    white-space: nowrap;
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: newsTickerAnimation 60s linear infinite;
    -webkit-animation: newsTickerAnimation 60s linear infinite;
    animation: newsTickerAnimation 60s linear infinite;
  }
}
/* for Firefox */
@-moz-keyframes newsTickerAnimation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}
/* for Chrome */
@-webkit-keyframes newsTickerAnimation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes newsTickerAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.one3d-description {
  ion-text:hover {
    animation-play-state: paused;
  }
}
.one3d-privacy-anchor {
  right: 20px;
  bottom: 8px;
  border-bottom: 0.5px solid var(--ion-color-686868);
}
//***  ==================================== description and privacy ends here
//***  ==================================== colors options starts here
.one3d-colors-options {
  bottom: 20px;
}
.one3d-colors-list-li {
  margin: 0 10px;
}
.one3d-colors-list-item {
  width: 24px;
  height: 24px;
}
.one3d-colors-list-box {
  width: 100%;
  height: 24px;
}
.one3d-color-roof {
  top: -4px;
  width: 100%;
  height: 50%;
  right: -8px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.one3d-colors-list-item::after {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  content: "";
  height: 100%;
  position: absolute;
  pointer-events: none;
  background: linear-gradient(
    134.62deg,
    rgba(153, 153, 153, 0) 1.97%,
    rgba(0, 0, 0, 0.25) 50.98%,
    rgba(153, 153, 153, 0) 100%
  );
}
.one3d-active-color {
  bottom: 6px;
  .one3d-colors-list-item {
    -webkit-clip-path: polygon(
      0% 0%,
      82.8% 0%,
      100.2% 17.3%,
      100.2% 99.5%,
      18.7% 99.8%,
      0% 78%
    );
    clip-path: polygon(
      0% 0%,
      82.8% 0%,
      100.2% 17.3%,
      100.2% 99.5%,
      18.7% 99.8%,
      0% 78%
    );
  }
  .one3d-color-border-outside {
    opacity: 1;
    visibility: visible;
  }
}
.one3d-color-border-outside {
  top: -4px;
  left: -4px;
  z-index: -1;
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background: rgba(var(--ion-color-white-rgb), 0.6);
  -webkit-clip-path: polygon(
    0% 0%,
    82.8% 0%,
    100.2% 17.3%,
    100.2% 99.5%,
    18.7% 99.8%,
    0% 78%
  );
  clip-path: polygon(
    0% 0%,
    82.8% 0%,
    100.2% 17.3%,
    100.2% 99.5%,
    18.7% 99.8%,
    0% 78%
  );
}
.one3d-color-border-inside {
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  background: var(--ion-color-252525);
  -webkit-clip-path: polygon(
    0% 0%,
    82.8% 0%,
    100.2% 17.3%,
    100.2% 99.5%,
    18.7% 99.8%,
    0% 78%
  );
  clip-path: polygon(
    0% 0%,
    82.8% 0%,
    100.2% 17.3%,
    100.2% 99.5%,
    18.7% 99.8%,
    0% 78%
  );
}
.one3d-color-name {
  top: -32px;
}
//***  ==================================== colors options ends here
//***  ==================================== discover more button starts here
.one3d-discover-more-box {
  right: 20px;
  bottom: 20px;
  .one3d-button-primary {
    -webkit-clip-path: polygon(
      0% 0%,
      93.8% 0%,
      100.1% 28.5%,
      100.1% 99.5%,
      5.4% 100%,
      0% 72.5%
    );
    clip-path: polygon(
      0% 0%,
      93.8% 0%,
      100.1% 28.5%,
      100.1% 99.5%,
      5.4% 100%,
      0% 72.5%
    );
    .one3d-button-primary-text {
      padding: 5px 16px;
      -webkit-clip-path: polygon(
        0% 0%,
        93.8% 0%,
        100.1% 28.5%,
        100.1% 99.5%,
        5.4% 100%,
        0% 72.5%
      );
      clip-path: polygon(
        0% 0%,
        93.8% 0%,
        100.1% 28.5%,
        100.1% 99.5%,
        5.4% 100%,
        0% 72.5%
      );
      img {
        margin-left: 4px;
      }
    }
  }
}
//*** ==================================== discover more button ends here
//*** ==================================== lhs hexagon panel starts here
.one3d-lhs-hexagon-panel,
.one3d-lhs-sub-section-panel {
  left: 30px;
  bottom: 44px;
}
.one3d-lhs-hexagon-panel-line,
.one3d-lhs-sub-section-panel-line {
  left: 0;
  bottom: 0;
  pointer-events: none;
}
.one3d-lhs-hexagon-panel-ul,
.one3d-lhs-sub-section-panel-ul {
  left: 0;
  // bottom: 40px;
  top: 54%;
  transform: translateY(-50%);
  margin-left: 16px;
}
// .one3d-lhs-sub-section-panel-ul {
//   bottom: 20px;
// }
.one3d-lhs-hexagon-panel-ul-2::before {
  left: 4px;
  z-index: 2;
  top: -30px;
  font-size: 8px;
  font-weight: 600;
  line-height: 1.5;
  font-style: italic;
  position: absolute;
  letter-spacing: 0.4px;
  content: "SWITCH VERSIONS";
  color: rgba(var(--ion-color-white), 0.2);
}
.one3d-lhs-sub-section-panel-ul::before {
  left: 4px;
  z-index: 2;
  top: -30px;
  font-size: 8px;
  font-weight: 600;
  line-height: 1.5;
  font-style: italic;
  position: absolute;
  letter-spacing: 0.4px;
  color: rgba(var(--ion-color-white), 0.4);
}
.one3d-lhs-hexagon-panel-li,
.one3d-lhs-sub-section-panel-li {
  margin: 8px 0;
  padding: 2px 6px;
}
.one3d-lhs-hexagon-panel-li::before,
.one3d-lhs-sub-section-panel-li::before {
  top: 50%;
  width: 1px;
  opacity: 0;
  z-index: 1;
  content: "";
  height: 30px;
  left: -15.4px;
  position: absolute;
  -webkit-transform: translate(0px, -50%);
  -moz-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  // -webkit-transition: 0.4s all ease;
  // -moz-transition: 0.4s all ease;
  // -ms-transition: 0.4s all ease;
  // transition: 0.4s all ease;
  background: rgb(5, 88, 5);
  background: linear-gradient(
    180deg,
    rgba(5, 88, 5, 1) 14%,
    rgba(0, 255, 0, 1) 50%,
    rgba(5, 88, 5, 1) 86%
  );
}
.one3d-lhs-hexagon-panel-li::after,
.one3d-lhs-sub-section-panel-li::after {
  top: 50%;
  z-index: 2;
  content: "";
  width: 10px;
  height: 10px;
  left: -20px;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: translate(0px, -50%);
  -moz-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  // -webkit-transition: 0.4s all ease;
  // -moz-transition: 0.4s all ease;
  // -ms-transition: 0.4s all ease;
  // transition: 0.4s all ease;
  background-image: url(assets/images/one3d-hexagon-icon.svg);
}
.one3d-lhs-active-option {
  background: linear-gradient(
    270deg,
    rgba(0, 144, 0, 0) 0%,
    rgba(0, 144, 0, 0.15) 77%
  );
  .one3d-lhs-hexagon-panel-text,
  .one3d-lhs-sub-section-panel-text {
    color: var(--ion-color-secondary-light);
  }
  &::before {
    opacity: 1;
  }
  &::after {
    width: 32px;
    height: 32px;
    left: -31px;
    // -webkit-transition: 0.4s all ease;
    // -moz-transition: 0.4s all ease;
    // -ms-transition: 0.4s all ease;
    // transition: 0.4s all ease;
    background-image: url(assets/images/one3d-active-hexagon.svg);
  }
}
.one3d-lhs-hexagon-panel-sub-text {
  color: rgba(var(--ion-color-white-rgb), 0.45);
}
//*** ==================================== lhs hexagon panel ends here
//*** ==================================== rhs hexagon panel starts here
.one3d-rhs-hexagon-panel {
  right: 30px;
  bottom: 46px;
}
.one3d-rhs-hexagon-panel-line {
  right: 0;
  bottom: 4px;
  pointer-events: none;
}
.one3d-rhs-hexagon-panel-ul {
  right: 0;
  // bottom: 20px;
  top: 54%;
  transform: translateY(-50%);
  margin-right: 16px;
}
.one3d-rhs-hexagon-panel-li {
  margin: 8px 0;
  padding: 2px 6px;
}
.one3d-rhs-hexagon-panel-li::before {
  top: 50%;
  width: 1px;
  opacity: 0;
  z-index: 1;
  content: "";
  height: 30px;
  right: -16px;
  position: absolute;
  -webkit-transform: translate(0px, -50%);
  -moz-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  // -webkit-transition: 0.4s all ease;
  // -moz-transition: 0.4s all ease;
  // -ms-transition: 0.4s all ease;
  // transition: 0.4s all ease;
  background: rgb(5, 88, 5);
  background: linear-gradient(
    180deg,
    rgba(5, 88, 5, 1) 14%,
    rgba(0, 255, 0, 1) 50%,
    rgba(5, 88, 5, 1) 86%
  );
}
.one3d-rhs-hexagon-panel-li::after {
  top: 50%;
  z-index: 2;
  content: "";
  width: 10px;
  height: 10px;
  right: -20.5px;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: translate(0px, -50%);
  -moz-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  // -webkit-transition: 0.4s all ease;
  // -moz-transition: 0.4s all ease;
  // -ms-transition: 0.4s all ease;
  // transition: 0.4s all ease;
  background-image: url(assets/images/one3d-hexagon-icon.svg);
}
.one3d-rhs-active-option {
  background: linear-gradient(
    90deg,
    rgba(0, 144, 0, 0) 0%,
    rgba(0, 144, 0, 0.15) 77%
  );
  .one3d-rhs-hexagon-panel-text {
    color: var(--ion-color-secondary-light);
  }
  &::before {
    opacity: 1;
  }
  &::after {
    width: 32px;
    height: 32px;
    right: -31.5px;
    // -webkit-transition: 0.4s all ease;
    // -moz-transition: 0.4s all ease;
    // -ms-transition: 0.4s all ease;
    // transition: 0.4s all ease;
    background-image: url(assets/images/one3d-active-hexagon.svg);
  }
}
//***   ====================================  rhs hexagon panel ends here
//***   ====================================  postcode and modal pop up starts here
.one3d-postcode-modal-box {
  min-width: 264px;
  border-radius: 0;
  width: fit-content;
  height: fit-content;
  border: 1px solid rgba(var(--ion-color-white-rgb), 0.2);
  background: rgba(var(--ion-color-black-rgb), 0.8);
  backdrop-filter: blur(4px);
  box-shadow: none;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 20px 30px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.one3d-postcode-button {
  top: 16px;
  right: 40px;
  background: transparent;
  .one3d-postcode-btn {
    .one3d-postcode-btn-text {
      border-bottom: 1px solid var(--ion-color-white);
    }
  }
}
.one3d-postcode-btn-icon {
  top: 3px;
}
.one3d-continue-btn-box {
  .one3d-button-primary {
    clip-path: polygon(
      0% 0%,
      94.8% 0%,
      100.1% 28.5%,
      100.1% 99.5%,
      5.4% 100%,
      0% 72.5%
    );
    .one3d-button-primary-text {
      padding: 9px 10px;
      clip-path: polygon(
        0% 0%,
        94.8% 0%,
        100.1% 28.5%,
        100.1% 99.5%,
        5.4% 100%,
        0% 72.5%
      );
    }
  }
}
.one3d-postcode-input {
  margin: 4px 0 0 0;
  --padding-end: 10px;
  --padding-start: 10px;
  min-height: 36px !important;
  background: var(--ion-color-white);
}
//***   ====================================  postcode and modal pop up ends here
.one3d-visualizer-ion-content,
.one3d-emaginator-ion-content,
.one3d-configurator-ion-content,
.one3d-home-ion-content {
  --background: transparent !important;
}
.one3d-home-ion-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.one3d-canvas-ui-wrapper {
  width: 100%;
  // height: calc(100% - 72px);
  height: 100%;
}
.one3d-pointer-events-none {
  pointer-events: none;
}
.one3d-pointer-events-all {
  pointer-events: all;
}
.one3d-bg-transparent {
  --background: transparent !important;
  background: transparent;
}
.desktop-hidden {
  display: block;
}
.mobile-hidden {
  display: none;
}
.one3d-tooltip-custom {
  top: -36px;
  left: 60px;
}
.one3d-tooltip-custom-box {
  border-radius: 2px;
  padding: 0px 6px;
  background-color: var(--ion-color-white);
}
.one3d-tooltip-custom-box::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 10px solid var(--ion-color-white);
}
.one3d-tooltip-custom-text {
  margin-right: 6px;
}
.one3d-tooltip-close-icon {
  width: 14px;
}
.one3d-button-primary-left-icon {
  margin-right: 10px;
}
.one3d-btn-group-right {
  right: 20px;
  bottom: 20px;
}
//**   ====================================  micro interactions starts here
.one3d-mi-panel {
  bottom: -2px;
}
.one3d-mi-panel-li {
  margin: 0;
  height: 64px;
  position: relative;
  text-align: center;
  margin-right: -18px;
  vertical-align: top;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 17% 100%);
  background: var(--ion-color-131313);
  -webkit-transition: 0.5s height ease, 1s clip-path ease;
  -moz-transition: 0.5s height ease, 1s clip-path ease;
  -ms-transition: 0.5s height ease, 1s clip-path ease;
  transition: 0.5s height ease, 1s clip-path ease;
  img {
    object-fit: contain;
    object-position: center;
    z-index: 2;
    width: 44px;
    height: 44px;
  }
}
.one3d-mi-panel-li:nth-child(2),
.one3d-mi-panel-li:nth-child(3) {
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0 100%);
}
.one3d-mi-panel-li:nth-last-child(3) {
  clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0 100%);
}
.one3d-mi-icon-box {
  margin: 0;
  display: flex;
  padding: 0px 24px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: var(--ion-color-131313);
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0 100%);
}
.one3d-mi-icon-active {
  display: none;
}
.one3d-mi-active {
  z-index: 2;
  height: 80px;
  -webkit-transition: 0.5s height ease, 1s clip-path ease;
  -moz-transition: 0.5s height ease, 1s clip-path ease;
  -ms-transition: 0.5s height ease, 1s clip-path ease;
  transition: 0.5s height ease;
  background: linear-gradient(
    176.23deg,
    rgba(0, 144, 0, 0.9) 11.99%,
    rgba(0, 144, 0, 0) 83.77%
  );
  box-shadow: 0px -8px 8px 0px rgba(0, 144, 0, 0.6);
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0 100%) !important;
  .one3d-mi-icon {
    display: none;
  }
  .one3d-mi-icon-active {
    display: block;
  }
}
.one3d-interior-exterior {
  right: 3px;
  background: linear-gradient(
    180deg,
    rgb(167 167 167) 0%,
    rgb(13, 13, 13) 100%
  );
  clip-path: polygon(0 0, 88% 0%, 100% 100%, 8% 100%);
  .one3d-mi-icon-box {
    padding: 0px 36px;
    clip-path: polygon(0 0, 88% 0%, 100% 100%, 8% 100%);
    background: linear-gradient(
      180deg,
      rgb(101 101 101) 0%,
      rgb(13 13 13) 100%
    );
  }
}
.one3d-colors-button-for-interior {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
  .one3d-mi-icon-box {
    ion-text {
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }
}
.one3d-interior-exterior {
  .one3d-mi-icon,
  .one3d-mi-icon-active {
    width: auto;
    height: auto;
  }
}
.tab:first-child {
  z-index: 999;
}
.tab.active:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #eee;
  -webkit-transform: perspective(3px) rotateX(1deg);
  -moz-transform: perspective(3px) rotateX(1deg);
  transform: perspective(3px) rotateX(1deg);
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #bbb;
  vertical-align: top;
  z-index: -1;
}
.tab.active:hover:before {
  background-color: #ddd;
}
.tab:nth-child(1) {
  z-index: 998;
}
.tab:nth-child(2) {
  z-index: 997;
}
.tab:nth-child(3) {
  z-index: 996;
}
.tab:nth-child(4) {
  z-index: 995;
}
.tab:nth-child(5) {
  z-index: 994;
}
.tab.active {
  z-index: 9999;
}
.one3d-overflow-auto {
  overflow: auto;
}
//**   ====================================  micro interactions ends here
//**   ====================================  features modal starts here
.one3d-full-screen-modal {
  min-width: 100%;
  border-radius: 0;
  width: 100%;
  height: 100%;
  --width: 100%;
  --height: 100%;
  background: rgba(0, 0, 0, 0.2);
  --background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(24px);
  --backdrop-filter: blur(24px);
  --box-shadow: none;
}
.one3d-sidePanelModal {
  min-width: 100%;
  border-radius: 0;
  width: 100%;
  height: 100%;
  --width: 100%;
  --height: 100%;
  background: rgba(0, 0, 0, 0.2);
  --background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(24px);
  --backdrop-filter: blur(24px);
  --box-shadow: none;
}
.one3d-back-arrow-circle {
  margin-right: 10px;
}
.one3d-full-screen-modal-lhs {
  width: 75%;
}
.one3d-full-screen-modal-rhs {
  width: 25%;
  background-color: rgba(var(--ion-color-white-rgb), 0.1);
}
.one3d-back-to-options-box {
  padding: 4px 10px;
}
.one3d-back-to-options:hover {
  .one3d-back-arrow-circle {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .one3d-back-to-options-text {
    opacity: 1;
  }
}
.one3d-accordion-content {
  padding: 0 8px 8px;
}
.one3d-features-story-box {
  overflow: auto;
  padding: 0 10px;
  scrollbar-width: none;
}
.one3d-features-story-box::-webkit-scrollbar {
  width: 0px;
}
/* Track */
.one3d-features-story-box::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.one3d-features-story-box::-webkit-scrollbar-thumb {
  background: transparent;
}
.one3d-back-arrow-circle {
  width: 36px;
}
.one3d-features-listings {
  .one3d-accordion-group,
  .one3d-accordion,
  .one3d-accordion-header {
    background-color: transparent;
    --background-color: transparent;
  }
  .ion-accordion-toggle-icon {
    display: none;
  }
}
.one3d-features-listings .one3d-accordion-header {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --inner-padding-end: 16px !important;
  --inner-padding-start: 0 !important;
}
.plt-iphone .one3d-features-listings .one3d-accordion-header::part(native) {
  right: 16px;
}
.plt-iphone .one3d-features-listings .one3d-accordion-header {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --inner-padding-end: 0 !important;
  --inner-padding-start: 0 !important;
}
.one3d-accordion-header::before {
  top: 50%;
  left: 12px;
  content: "";
  width: 14px;
  height: 2px;
  position: absolute;
  border-radius: 8px;
  background-color: var(--ion-color-white);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.one3d-accordion-header::after {
  top: 50%;
  left: 18px;
  content: "";
  width: 2px;
  height: 14px;
  position: absolute;
  border-radius: 8px;
  background-color: var(--ion-color-white);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  transition: 0.4s all ease;
}
.one3d-features-li {
  margin: 0 0 12px;
  padding: 2px 8px 2px 32px;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  transition: 0.4s all ease;
  &::after {
    top: 50%;
    width: 8px;
    right: -8px;
    content: "";
    height: 100%;
    position: absolute;
    border-radius: 8px;
    transform: translate(0, -50%);
    background-color: var(--ion-color-black);
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4s all ease;
    -ms-transition: 0.4s all ease;
    transition: 0.4s all ease;
  }
  &:last-child {
    margin: 0;
  }
}
.one3d-feature-active {
  &::after {
    background-color: var(--ion-color-secondary);
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4s all ease;
    -ms-transition: 0.4s all ease;
    transition: 0.4s all ease;
  }
  .one3d-features-li-text {
    color: var(--ion-color-secondary);
    text-shadow: 0.5px 0.5px 0.5px var(--ion-color-secondary);
  }
}
.one3d-features-li:hover .one3d-features-li-text {
  color: var(--ion-color-secondary);
}
.accordion-expanded .one3d-accordion-header::after {
  opacity: 0;
}
.one3d-accordion-content::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary);
}
.one3d-scroll-up-for-next-wrap {
  bottom: 20%;
}
.one3d-animated-arrow {
  padding: 0 0 120px;
}
.one3d-arrow {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate3d(-50%, -50%, 0);
}
.one3d-arrow-first {
  -webkit-animation: arrowMovement 2s ease-in-out infinite;
  -moz-animation: arrowMovement 2s ease-in-out infinite;
  animation: arrowMovement 2s ease-in-out infinite;
}
.one3d-arrow-second {
  -webkit-animation: arrowMovement 2s 1s ease-in-out infinite;
  -moz-animation: arrowMovement 2s 1s ease-in-out infinite;
  animation: arrowMovement 2s 1s ease-in-out infinite;
}
@-webkit-keyframes arrowMovement {
  0% {
    opacity: 0;
    top: 30%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes arrowMovement {
  0% {
    opacity: 0;
    top: 30%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrowMovement {
  0% {
    opacity: 0;
    top: 30%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
//**   ====================================  features modal ends here
//**   ====================================  accessories modal starts here
.one3d-accessories-tabs-ul {
  justify-content: space-around;
}
.one3d-accessories-tabs-li {
  cursor: pointer;
  padding: 8px 20px;
  position: relative;
}
.one3d-accessories-tabs-li::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  transition: 0.4s all ease;
  background-color: var(--ion-color-white);
}
.one3d-active-acc-tab::after {
  opacity: 1;
  visibility: visible;
}
.one3d-accessories-box {
  padding: 0 12px 12px;
}
.one3d-acc-left-box {
  width: 35%;
}
.one3d-acc-right-box {
  width: 65%;
  padding: 4px 8px;
}
.one3d-acc-box {
  border-radius: 4px;
  margin-bottom: 8px;
  background: rgba(0, 0, 0, 0.4);
}
.one3d-acc-added-highlighter {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  overflow: hidden;
  .color-727272,
  .color-9a9a9c {
    color: var(--ion-color-white);
  }
}
.one3d-acc-box:hover .one3d-acc-right-box {
  background: rgba(0, 0, 0, 1);
}
.one3d-acc-box:hover .one3d-acc-thumbnail {
  filter: grayscale(1);
}
.one3d-accessories-tabs-panel-inside {
  height: calc(100% - 100px);
}
.one3d-accessories-tabs-content-container {
  padding: 8px;
  height: calc(100% - 36px);
  height: 100%;
}
.one3d-acc-right-box-top {
  margin-bottom: 8px;
}
.one3d-add-acc-button {
  opacity: 0.6;
  padding: 6px 20px;
  border-radius: 2px;
  border: 2px solid var(--ion-color-white);
  svg {
    width: 12px;
    margin-right: 4px;
  }
  &:hover {
    opacity: 1;
  }
}
.one3d-remove-acc-button {
  padding: 10px 14px;
  border-radius: 2px;
  background: rgba(255, 70, 70, 0.3);
  border: 1px solid rgba(var(--ion-color-white-rgb), 0.4);
  &:hover {
    background: rgba(255, 70, 70, 0.6);
  }
}
.one3d-footer-description {
  padding: 20px 10px 10px;
}
.one3d-white-button {
  margin: 0;
  min-height: 48px;
  --border-radius: 0;
}
.one3d-black-button {
  min-height: 48px;
  --border-radius: 0;
  .one3d-cart-icon {
    width: 24px;
  }
  .one3d-cart-text {
    margin: 0 6px;
  }
  .one3d-cart-count {
    width: 20px;
    height: 20px;
    display: flex;
    line-height: 1;
    font-style: normal;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-color: var(--ion-color-secondary);
  }
}
//**   ====================================  accessories modal ends here
//**   ====================================  summary modal starts here
.one3d-summary-modal {
  --width: 100%;
  --height: 100%;
}
.one3d-summary-tabs-bar {
  padding-left: 20px;
}
.one3d-summary-box-inside {
  padding: 20px;
}
.one3d-summary-tabs-li {
  padding: 16px;
  border-bottom: 1px solid rgba(191, 191, 191, 0.15);
  .one3d-arrow-left {
    width: 24px;
    left: 8px;
  }
  &::after {
    top: 50%;
    right: 0;
    opacity: 0;
    width: 8px;
    height: 90%;
    content: "";
    position: absolute;
    border-radius: 12px;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-color: var(--ion-color-secondary);
    -webkit-transition: 0.6s all ease;
    -moz-transition: 0.6s all ease;
    -ms-transition: 0.6s all ease;
    transition: 0.6s all ease;
  }
  &:hover {
    .one3d-summary-tabs-text {
      color: var(--ion-color-secondary);
      text-shadow: 0.5px 0.5px 0.5px var(--ion-color-secondary);
    }
    .one3d-arrow-left {
      left: 0;
      path {
        stroke: var(--ion-color-secondary);
      }
    }
  }
}
.one3d-active-tab {
  pointer-events: none;
  .one3d-summary-tabs-text {
    color: var(--ion-color-secondary);
    text-shadow: 0.5px 0.5px 0.5px var(--ion-color-secondary);
  }
  .one3d-arrow-left {
    path {
      stroke: var(--ion-color-secondary);
    }
  }
}
.one3d-model-details-middle-container {
  margin: 60px 0 0;
}
.one3d-summary-box-main {
  padding: 0 10px 10px;
}
.one3d-summary-box-inside {
  background: linear-gradient(
    180.1deg,
    rgba(91, 91, 91, 0.4) 0.08%,
    #000000 140.79%
  );
}
.one3d-model-details-box-row {
  background-color: rgba(255, 255, 255, 0.1);
}
.one3d-model-details-bottom-box {
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url(assets/images/one3d-model-details-bottom-box.png);
  padding: 48px 30px;
}
.one3d-price-breakup-border {
  border-bottom: 1px solid var(--ion-color-5a5a5a);
}
.one3d-price-breakup-data-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.one3d-price-breakup-heading {
  margin: 10px auto;
}
.one3d-price-breakup-heading {
  margin: 20px auto 10px;
}
.one3d-model-details-box-row {
  position: relative;
}
.one3d-model-details-box-col-left {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.one3d-model-details-box-col-right {
  padding: 20px 20px 20px 80px;
}
.one3d-model-details-box-right {
  margin: 10px 0;
}
//**   ====================================  summary modal ends here
//***   ====================================  test drive form and modal pop up starts here
.one3d-test-drive-modal {
  --min-width: 264px;
  --border-radius: 0;
  --width: fit-content;
  --height: fit-content;
  --background: transparent;
  backdrop-filter: blur(4px);
  --box-shadow: none;
  &::part(content) {
    padding: 10px 48px 10px 10px;
  }
}
.confirmation-modal {
  --min-width: 264px;
  --border-radius: 0;
  --width: fit-content;
  --height: fit-content;
  --background: transparent;
  backdrop-filter: blur(8px);
  --box-shadow: none;
  &::part(content) {
    padding: 10px 48px 10px 10px;
  }
}
.one3d-test-drive-modal-box {
  border: 1px solid rgba(var(--ion-color-white-rgb), 0.2);
  background: rgba(var(--ion-color-black-rgb), 0.5);
  padding: 20px 30px;
}
.one3d-confirmation-modal-wrapper {
  border: 1px solid rgba(var(--ion-color-white-rgb), 0.2);
  background: rgba(var(--ion-color-black-rgb), 0.7);
  padding: 20px 30px;
  max-width: 600px;
}
.one3d-test-drive-button {
  top: 16px;
  right: 40px;
  background: transparent;
  .one3d-test-drive-btn {
    .one3d-test-drive-btn-text {
      border-bottom: 1px solid var(--ion-color-white);
    }
  }
}
.one3d-test-drive-btn-icon {
  top: 3px;
}
.one3d-test-drive-btn-box {
  .one3d-button-primary {
    clip-path: polygon(
      0% 0%,
      94.8% 0%,
      100.1% 28.5%,
      100.1% 99.5%,
      5.4% 100%,
      0% 72.5%
    );
    .one3d-button-primary-text {
      padding: 9px 10px;
      clip-path: polygon(
        0% 0%,
        94.8% 0%,
        100.1% 28.5%,
        100.1% 99.5%,
        5.4% 100%,
        0% 72.5%
      );
    }
  }
  .one3d-arrow-right {
    margin-left: 4px;
  }
}
.one3d-test-drive-input-country-code {
  margin-top: 4px;
  height: 48px;
  background-color: var(--ion-color-white);
}
.one3d-test-drive-input {
  margin: 4px 0 0 0;
  --padding-end: 10px;
  --padding-start: 10px;
  min-height: 36px !important;
  background: var(--ion-color-white);
}
.one3d-dealership-select {
  outline: none;
  margin: 4px 0 0 0;
  padding: 0 10px;
  min-height: 36px !important;
  background: var(--ion-color-white);
  -webkit-appearance: none;
  appearance: none;
}
.one3d-arrow-down {
  right: 20px;
  top: calc(50% - 6px);
}
.one3d-close-test-drive-form {
  right: -36px;
  top: 0;
}
.one3d-close-test-drive-form:hover,
.one3d-color-options-close:hover {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.open-end-menu {
  top: 20px;
  left: 200px;
}
.one3d-test-drive-form-dots {
  padding: 20px 0 10px;
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: var(--ion-color-727272);
  }
  .active-dot {
    background-color: var(--ion-color-secondary);
  }
}
.one3d-lhs-shadow {
  left: 0;
  bottom: 0;
  width: 12%;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.one3d-rhs-shadow {
  right: 0;
  bottom: 0;
  width: 12%;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.one3d-feature-description-shadow {
  right: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.one3d-bottom-shadow {
  right: 0;
  bottom: 0;
  height: 12%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
//***   ====================================  test drive form and modal pop up ends here
//***   ====================================  main preloader starts here
.one3d-tap-anywhere {
  margin-left: 12px;
}
.one3d-preloader-tag-line {
  background: #c5ffc9;
  background: linear-gradient(to right, #c5ffc9 0%, #769979 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-animation: fadeInOutMainLoader 20s ease infinite;
  -moz-animation: fadeInOutMainLoader 20s ease infinite;
  animation: fadeInOutMainLoader 20s ease infinite;
}
.one3d-main-preloader-progress-bar-text-carousel {
  height: 50px;
  position: relative;
}
.one3d-preloader-tag-line-1 {
  animation-delay: 0s;
}
.one3d-preloader-tag-line-2 {
  animation-delay: 10s;
}
@-webkit-keyframes fadeInOutMainLoader {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeInOutMainLoader {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
@keyframes fadeInOutMainLoader {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
.one3d-main-preloader-video-box {
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: 50%;
  }
}
.one3d-main-preloader-video {
  top: 50%;
  left: 50%;
  width: 100%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.one3d-main-preloader-progress-bar-shape {
  padding-top: 30px;
}
.one3d-main-preloader-progress-bar {
  bottom: 36px;
  padding: 0 20px;
}
.one3d-main-preloader-progress-bar-sliders {
  left: 4px;
  height: 50px;
  overflow: hidden;
  width: calc(100% - 8px);
}
.one3d-main-preloader-left-bars {
  left: -10.5%;
}
.one3d-main-preloader-right-bars {
  right: -11%;
}
.one3d-main-preloader-progress-bar-shape-overlay {
  bottom: 0px;
  width: 100%;
  background: #000;
  height: 100%;
  z-index: 2;
}
.one3d-main-preloader-sound-contain {
  bottom: 16px;
  position: relative;
}
.one3d-main-preloader-sound-percentage {
  margin-bottom: -10px;
}
.one3d-loader-visualizer {
  position: absolute;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  pointer-events: none;
}
//***   ====================================  main preloader ends here
//***   ====================================  drive button starts
.one3d-drive-button-icon-box {
  left: 2px;
  margin: 0;
  z-index: 2;
  height: 64px;
  margin-left: 16px;
  padding: 0px 20px;
  position: relative;
  text-align: center;
  margin-right: 8px;
  vertical-align: top;
}
.one3d-drive-button-icon {
  width: 64px;
  height: 64px;
}
.one3d-drive-button-icon-box::before {
  top: auto;
  left: 0px;
  right: 0px;
  content: "";
  bottom: 8px;
  width: 100%;
  z-index: -1;
  height: 100%;
  border-radius: 0px;
  position: absolute;
  vertical-align: top;
  display: inline-block;
  border-bottom-color: transparent;
  -webkit-transform: perspective(4px) rotateX(2deg);
  -moz-transform: perspective(4px) rotateX(2deg);
  transform: perspective(4px) rotateX(2deg);
  background-color: var(--ion-color-131313);
  border: 2px solid var(--ion-color-secondary);
  box-shadow: 0px -4px 4px 0px rgba(0, 144, 0, 0.4);
}
.one3d-drive-button-label {
  top: 2px;
  padding: 14px 48px;
}
.one3d-drive-button-label::before {
  top: auto;
  left: 0px;
  right: 0px;
  content: "";
  bottom: 0px;
  width: 100%;
  z-index: -1;
  height: 100%;
  border-radius: 0px;
  position: absolute;
  vertical-align: top;
  display: inline-block;
  transform: perspective(10px) rotateX(1deg) skewX(26deg);
  background: linear-gradient(176.35deg, #006100 23.08%, #243e24 114.41%);
  border: 2px solid var(--ion-color-secondary);
  box-shadow: 0px -4px 4px 0px rgba(0, 144, 0, 0);
}
//***   ====================================  drive button ends
//***   ====================================  park button starts
.one3d-park-button-box {
  .one3d-drive-button-icon {
    width: 44px;
    height: 44px;
  }
  .one3d-drive-button-label {
    order: 1;
  }
  .one3d-drive-button-label::before {
    -webkit-transform: perspective(10px) rotateX(1deg) skewX(-26deg);
    -moz-transform: perspective(10px) rotateX(1deg) skewX(-26deg);
    transform: perspective(10px) rotateX(1deg) skewX(-26deg);
  }
  .one3d-drive-button-icon-box {
    order: 2;
    left: -10px;
  }
}
//***   ====================================  park button ends
//***   ====================================  variant info corner starts here
.one3d-variant-info-corner {
  top: 80px;
  left: 60px;
}
.one3d-variant-name {
  color: rgba(var(--ion-color-white-rgb), 0.4);
}
.one3d-variant-price-text {
  margin-right: 10px;
  border-bottom: 1px solid var(--ion-color-white);
}
//***   ====================================  variant info corner ends here
//***   ====================================  seating rows options starts here
.one3d-seating-rows-options {
  .one3d-colors-list-box {
    padding: 0 6px;
    background-color: var(--ion-color-black);
  }
  .one3d-colors-list-item {
    width: auto;
  }
  .one3d-colors-list-item::after {
    display: none;
    background: none;
  }
  .one3d-color-border-inside {
    background: var(--ion-color-243e24);
  }
  .one3d-color-options-close {
    margin-right: 10px;
    position: relative;
    bottom: 4px;
  }
}
.one3d-cross-icon {
  width: 44px;
}
//***   ====================================  seating rows options ends here
//***   ====================================  download and share modal starts here
.one3d-download-share-modal {
  .one3d-button-primary {
    clip-path: polygon(
      0% 0%,
      94.8% 0%,
      100.1% 28.5%,
      100.1% 99.5%,
      5.4% 100%,
      0% 100%
    );
    .one3d-button-primary-text {
      clip-path: polygon(
        0% 0%,
        94.8% 0%,
        100.1% 28.5%,
        100.1% 99.5%,
        5.4% 100%,
        0% 100%
      );
    }
  }
}
.one3d-copy-url-input {
  height: 52px;
  background-color: transparent;
  border: 2px solid var(--ion-color-c4c4c4);
}
.one3d-copy-url-box {
  padding: 16px;
}
.one3d-copy-url-text {
  width: 60%;
  overflow: hidden;
  padding-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.one3d-copy-url-btn {
  width: 40%;
  padding: 0 8px;
  border-left: 1px solid rgba(var(--ion-color-b5b5b5-rgb), 0.5);
}
.one3d-in-progress-loader-icon {
  width: 16px;
  height: 16px;
  -webkit-animation: one3dLoading 1s linear infinite;
  -moz-animation: one3dLoading 1s linear infinite;
  animation: one3dLoading 1s linear infinite;
}
@-webkit-keyframes one3dLoading {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes one3dLoading {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes one3dLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
//***   ====================================  download and share modal ends here
//**   ====================================  Tap and drag to rotate starts here
.one3d-tutorial {
  left: 50%;
  z-index: 99;
  bottom: 30%;
  position: absolute;
  text-align: center;
  pointer-events: none;
  // opacity: 0;
  // visibility: hidden;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.one3d-tutorial-tap-drag-adas-screen {
  top: 30px;
  bottom: auto;
}
.one3d-tutorial-show {
  opacity: 1;
  visibility: visible;
}
.one3d-tap-drag-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}
.one3d-tap-drag-strong {
  font-weight: 600;
}
.one3d-tutorial-tap-drag-circle,
.one3d-tutorial-tap-drag-circle-border {
  -webkit-animation: oscillateX 4s infinite linear;
  -moz-animation: oscillateX 4s infinite linear;
  -ms-animation: oscillateX 4s infinite linear;
  -o-animation: oscillateX 4s infinite linear;
  animation: oscillateX 4s infinite linear;
}
@-webkit-keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@-moz-keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
//**   ====================================  Tap and drag to rotate ends here
//**   ====================================  Pinch or scroll to zoom in & out starts here
.one3d-pinch-scroll-up-arrow {
  -webkit-animation: oscillateUpY 2s infinite linear;
  -moz-animation: oscillateUpY 2s infinite linear;
  -ms-animation: oscillateUpY 2s infinite linear;
  -o-animation: oscillateUpY 2s infinite linear;
  animation: oscillateUpY 2s infinite linear;
}
@-webkit-keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.one3d-pinch-scroll-down-arrow {
  -webkit-animation: oscillateDownY 2s infinite linear;
  -moz-animation: oscillateDownY 2s infinite linear;
  -ms-animation: oscillateDownY 2s infinite linear;
  -o-animation: oscillateDownY 2s infinite linear;
  animation: oscillateDownY 2s infinite linear;
}
@-webkit-keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
//**   ====================================  Pinch or scroll to zoom in & out ends here
//**   ====================================  adas park mode info starts here
.one3d-adas-park-mode-info-wrapper {
  left: 40px;
  max-width: 332px;
}
.one3d-adas-park-mode-info-header {
  width: 80%;
  padding: 10px 20px;
  clip-path: polygon(0 0, 76% 0, 100% 100%, 0% 100%);
  background-color: rgba(var(--ion-color-white-rgb), 0.3);
}
.one3d-adas-park-mode-info-list-box {
  padding: 20px;
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 0.15) -10.61%,
  //   rgba(255, 255, 255, 0) 156.06%
  // );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) -10.61%,
    rgba(0, 0, 0, 0.4) 156.06%
  );
  clip-path: polygon(
    0% 0%,
    90.8% 0%,
    100.1% 9.5%,
    100.1% 99.5%,
    0% 100%,
    0% 72.5%
  );
  backdrop-filter: blur(8px);
}
.one3d-adas-park-mode-info-li {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.one3d-adas-park-mode-info-count {
  min-width: 24px;
  margin-right: 16px;
}
.one3d-adas-main-heading {
  top: 6%;
}
.one3d-feature-description-container {
  min-width: 500px;
  max-width: 60%;
  bottom: 0;
  width: max-content;
  .one3d-drive-mode-info-border {
    left: -8px;
    width: 40%;
    right: auto;
  }
  .one3d-feature-description-header {
    padding-left: 60px;
  }
  .one3d-adas-park-mode-info-header {
    width: 100%;
    clip-path: polygon(24% 0, 100% 0, 100% 100%, 0% 100%);
    background-color: rgba(var(--ion-color-white-rgb), 0.6);
  }
  .one3d-drive-modes-info-content-box {
    background: rgba(var(--ion-color-black-rgb), 0.2);
    clip-path: polygon(4% 0, 100% 0, 100% 100%, 0 100%, 0 16%);
  }
  .one3d-adas-park-mode-info-list-box {
    clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%, 0 12%);
  }
  .one3d-feature-description-close {
    top: -48px;
    right: -4px;
    cursor: pointer;
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4s all ease;
    transition: 0.4s all ease;
    img {
      width: 40px;
    }
    &:hover {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
//**   ====================================  adas park mode info ends here
//**   ====================================  drive modes info starts here
.one3d-drive-modes-info-wrapper {
  max-width: 640px;
}
.one3d-drive-modes-info-header {
  width: max-content;
  padding: 6px 68px 6px 20px;
  clip-path: polygon(0 0, 76% 0, 100% 100%, 0% 100%);
  background: linear-gradient(180deg, #ffffff 0%, #999999 100%);
}
.one3d-drive-modes-info-content {
  color: #ddd;
  strong {
    color: #fff;
  }
}
.one3d-drive-modes-info-content-box {
  padding: 16px 20px;
  background: rgba(var(--ion-color-black-rgb), 0.3);
  clip-path: polygon(
    0% 0%,
    95.8% 0%,
    100.1% 20.5%,
    100.1% 100.5%,
    0% 100%,
    0% 72.5%
  );
  backdrop-filter: blur(8px);
}
.one3d-drive-mode-info-border {
  top: -8px;
  right: -8px;
  width: 300px;
}
//**   ====================================  drive modes info ends here
//**    ====================================  loader progress
.one3d-loader-progress-box {
  margin-top: 30px;
  margin-bottom: 60px;
}
.one3d-loader-progress {
  position: relative;
  width: 5px;
  height: 5px;
  top: 49%;
  top: -webkit-calc(50% - 43px);
  top: calc(50% - 2.5px);
  left: 49%;
  left: -webkit-calc(50% - 43px);
  left: calc(50% - 2.5px);
  background-color: #009000;
  border-radius: 50px;
}
.one3d-loader-progress:before {
  content: "";
  position: absolute;
  top: -22px;
  border-top: 3px solid #009000;
  border-right: 3px solid #009000;
  border-radius: 0 50px 0px 0;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-animation: loaderProgress 1.5s linear infinite;
  animation: loaderProgress 1.5s linear infinite;
}
.one3d-loader-progress:after {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  border-bottom: 3px solid #009000;
  border-left: 3px solid #009000;
  border-radius: 0 0px 0px 50px;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  -webkit-animation: loaderProgress 1.5s linear infinite;
  animation: loaderProgress 1.5s linear infinite;
}
@-webkit-keyframes loaderProgress {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes loaderProgress {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loaderProgress {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.uppercase {
  text-transform: uppercase;
}
.one3d-short-loader-text-carousel {
  height: 50px;
  position: relative;
}
.one3d-short-loader-text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-animation: fadeInOut 10s ease infinite;
  -moz-animation: fadeInOut 10s ease infinite;
  animation: fadeInOut 10s ease infinite;
}
.one3d-short-loader-text-1 {
  animation-delay: 0s;
}
.one3d-short-loader-text-2 {
  animation-delay: 5s;
}
@-webkit-keyframes fadeInOut {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeInOut {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
@keyframes fadeInOut {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
.one3d-price-reveal-soon-wrap {
  padding: 16px;
}
.one3d-added-accessories-box-main {
  .one3d-summary-box-inside {
    overflow: auto;
    height: calc(100% - 40px);
  }
  .one3d-model-details-middle-container {
    margin-top: 10px;
  }
  .one3d-delete-red-icon {
    width: 20px;
  }
  .one3d-acc-box {
    margin-bottom: 0;
    background: var(--ion-color-555555);
  }
  .one3d-acc-right-box {
    padding: 12px;
  }
  .one3d-acc-right-box-top {
    height: 100%;
    margin: 0;
  }
  .one3d-acc-right-box {
    display: flex;
    flex-direction: column;
  }
  .one3d-acc-left-box {
    display: flex;
    img {
      height: 120px;
      object-fit: cover;
    }
  }
  .one3d-added-acc-container {
    flex-wrap: wrap;
  }
}
.one3d-added-acc-box {
  width: 50%;
}
.one3d-add-accessories-now-btn {
  margin: 0 0 20px;
  padding: 20px 40px;
  border-radius: 2px;
  border: 2px solid rgba(var(--ion-color-white-rgb), 0.5);
}
.one3d-add-accessories-now-text {
  padding: 0 22vw;
}
.one3d-add-accessories-now-plus-icon {
  margin-right: 12px;
}
.one3d-features-description-box-border-corner {
  right: 0;
  bottom: 0;
  width: 50%;
  display: flex;
  img {
    width: 100%;
  }
}
.one3d-colors-button-for-interior {
  display: none;
}
.one3d-car-interior-view-active {
  .one3d-colors-button-for-interior {
    display: flex;
  }
}
.one3d-trim-name {
  margin-bottom: 12px;
}
.one3d-offer-heading {
  margin-bottom: 8px;
}
.one3d-offer-description {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(var(--ion-color-white-rgb), 0.1);
}
.one3d-offers-box-main {
  .one3d-summary-acc-box {
    height: calc(100% - 30px);
    overflow: auto;
    .one3d-add-accessories-now-wrapper {
      padding-bottom: 60px;
    }
  }
}
.one3d-feature-desc-info-icon-box {
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.one3d-feature-active {
  .one3d-feature-desc-info-icon-box {
    opacity: 1;
    visibility: visible;
  }
}
.one3d-charge-fox-anchor {
  text-decoration: underline;
  color: var(--ion-color-9a9a9c);
}
.one3d-email-description-mobile {
  display: block;
  margin-top: 8px;
}
.one3d-email-description-mobile-landscape {
  display: none;
}
//***   ==================================== desktop screen greater than 992px starts here
@media only screen and (min-width: 992px) {
  .one3d-button-primary:hover .one3d-button-primary-text,
  .one3d-button-secondary:hover .one3d-button-secondary-text {
    background: #005519;
  }
  #summaryModal:hover .one3d-button-secondary-text {
    background: #222;
  }
  .desktop-hidden {
    display: none;
  }
  .mobile-hidden {
    display: block;
  }
  .one3d-lhs-hexagon-panel,
  .one3d-lhs-sub-section-panel {
    left: 2.5vw;
    bottom: 3.5vw;
  }
  .one3d-lhs-hexagon-panel-ul {
    // bottom: 11vw;
    margin-left: 2vw;
    .one3d-lhs-hexagon-panel-li {
      margin: 1.5vw 0;
      padding: 0.2vw 0.5vw;
      &::before {
        width: 2px;
        height: 8vw;
        left: -2vw;
      }
      &::after {
        width: 1.2vw;
        height: 1.2vw;
        left: -2.5vw;
      }
      .one3d-lhs-hexagon-panel-text {
        font-size: 1vw;
      }
    }
    .one3d-lhs-active-option {
      &::after {
        width: 4vw;
        height: 4vw;
        left: -3.9vw;
      }
    }
  }
  .one3d-lhs-sub-section-panel-ul {
    // bottom: 9vw;
    margin-left: 2vw;
    .one3d-lhs-sub-section-panel-li {
      margin: 1.5vw 0;
      padding: 0.2vw 0.5vw;
      &::before {
        width: 2px;
        height: 8vw;
        left: -2vw;
      }
      &::after {
        width: 1.2vw;
        height: 1.2vw;
        left: -2.5vw;
      }
      .one3d-lhs-sub-section-panel-text {
        font-size: 1vw;
      }
    }
    .one3d-lhs-active-option {
      &::after {
        width: 4vw;
        height: 4vw;
        left: -3.9vw;
      }
    }
  }
  .one3d-rhs-hexagon-panel {
    right: 2.5vw;
    bottom: 3.5vw;
  }
  .one3d-rhs-hexagon-panel-ul {
    // bottom: 11vw;
    margin-right: 2vw;
    .one3d-rhs-hexagon-panel-li {
      margin: 1.5vw 0;
      padding: 0.2vw 0.5vw;
      &::before {
        width: 2px;
        height: 8vw;
        right: -2vw;
      }
      &::after {
        width: 1.2vw;
        height: 1.2vw;
        right: -2.5vw;
      }
      .one3d-rhs-hexagon-panel-text {
        font-size: 1vw;
      }
    }
    .one3d-rhs-active-option {
      &::after {
        width: 4vw;
        height: 4vw;
        right: -3.9vw;
      }
    }
  }
  .one3d-button-primary-text {
    font-size: 1vw;
    padding: 0.4vw 1.3vw;
  }
  .one3d-btn-group-right {
    right: 3vw;
    bottom: 1.6vw;
  }
  .one3d-button-secondary-text,
  .one3d-button-tertiary-text {
    font-size: 1vw;
    padding: 0.4vw 1.5vw;
  }
  .one3d-test-drive-speaker-box {
    left: 3vw;
    bottom: 1.6vw;
  }
  .one3d-discover-more-box {
    right: 3vw;
    bottom: 1.6vw;
    .one3d-button-primary {
      .one3d-button-primary-text {
        padding: 0.4vw 1.3vw;
      }
    }
  }
  .one3d-sound-button-contain {
    margin-left: 2vw;
  }
  .one3d-button-sound,
  .one3d-button-muted {
    img {
      width: 1.4vw;
      height: 1.4vw;
    }
  }
  .one3d-privacy-anchor {
    right: 3vw;
    font-size: 0.5vw;
  }
  .one3d-colors-list-item {
    width: 2.8vw;
    height: 2.8vw;
  }
  .one3d-colors-list-box {
    height: 2.8vw;
  }
  .one3d-color-roof {
    top: -0.3vw;
    right: -1vw;
  }
  .one3d-color-name-text {
    font-size: 1vw;
  }
  .one3d-colors-list-li {
    margin: 0 1vw;
  }
  .one3d-colors-options {
    bottom: 1.5vw;
  }
  .one3d-tooltip-custom {
    top: -3.3vw;
    left: 5vw;
  }
  .one3d-tooltip-custom-text {
    font-size: 12px;
    margin-right: 10px;
  }
  .one3d-tooltip-custom-box {
    padding: 4px 8px;
    border-radius: 4px;
  }
  .one3d-tooltip-close-icon {
    width: 20px;
  }
  .one3d-tooltip-custom-box::after {
    bottom: -13px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 14px solid var(--ion-color-white);
  }
  .one3d-test-drive-modal-box {
    .fs-12 {
      font-size: 20px;
    }
    .fs-10 {
      font-size: 16px;
    }
    ion-input {
      height: 48px;
    }
    .one3d-dealership-select {
      height: 48px;
    }
    .one3d-button-primary {
      margin: 10px 0 0 0;
      .one3d-button-primary-text {
        padding: 14px 10px;
      }
    }
  }
  .one3d-close-test-drive-form {
    right: -44px;
    img {
      width: 36px;
    }
  }
  .one3d-postcode-modal-box {
    .fs-12 {
      font-size: 20px;
    }
    .fs-10 {
      font-size: 16px;
    }
    ion-input {
      height: 48px;
    }
    .one3d-button-primary {
      margin: 10px 0 0 0;
      .one3d-button-primary-text {
        padding: 14px 10px;
      }
    }
  }
  .one3d-accordion-group {
    .fs-14 {
      font-size: 20px;
    }
    .fs-10 {
      font-size: 16px;
    }
  }
  .one3d-car-interior-view-active {
    .one3d-lhs-hexagon-panel,
    .one3d-lhs-sub-section-panel {
      bottom: 0;
    }
    .one3d-lhs-hexagon-panel-ul,
    .one3d-lhs-sub-section-panel-ul {
      top: 46%;
    }
  }
}
//***   ==================================== desktop screen greater than 1366px ends here
//***   ==================================== mobile screen starts here
@media only screen and (max-width: 991px) {
  .one3d-scroll-up-for-next {
    .fs-24 {
      font-size: 14px;
    }
  }
  .one3d-arrow {
    img {
      width: 16px;
    }
  }
  .one3d-animated-arrow {
    padding: 0 0 40px;
  }
  .one3d-scroll-up-for-next-wrap {
    bottom: -8px;
  }
  .one3d-button-primary-left-icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
  .one3d-back-to-options-text {
    font-size: 12px;
  }
  .one3d-back-arrow-circle {
    width: 28px;
    margin-right: 4px;
  }
  .one3d-mi-panel-li {
    height: 44px;
  }
  .one3d-mi-active {
    height: 52px;
  }
  .one3d-interior-exterior {
    right: -3px;
    clip-path: polygon(0 0, 80% 0%, 100% 100%, 17% 100%);
    .one3d-mi-icon-box {
      padding: 0px 20px;
      clip-path: polygon(0 0, 80% 0%, 100% 100%, 17% 100%);
    }
  }
  .one3d-test-drive-modal {
    --width: 84%;
  }
  .one3d-test-drive-form-step-inside {
    display: flex;
    align-items: flex-end;
  }
  .one3d-test-drive-input-field {
    width: 40%;
    flex: 0 0 auto;
    padding-right: 10px;
    margin-bottom: 8px !important;
  }
  .one3d-test-drive-btn-box {
    width: 20%;
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
  .one3d-test-drive-form-dots {
    padding: 10px 0 10px;
  }
  .one3d-main-preloader-progress-bar {
    bottom: 16px;
  }
  .one3d-main-preloader-progress-bar-shape {
    padding-top: 20px;
  }
  .one3d-main-preloader-percentage {
    .fs-40 {
      font-size: 20px;
    }
  }
  .one3d-main-preloader-sound-contain {
    bottom: 6px;
  }
  .one3d-main-preloader-video {
    width: 90%;
  }
  .one3d-mi-panel-li img {
    width: 30px;
    height: 30px;
  }
  .one3d-mi-panel-li {
    .fs-24 {
      font-size: 16px;
    }
  }
  .one3d-interior-exterior .one3d-mi-icon,
  .one3d-interior-exterior .one3d-mi-icon-active {
    width: auto;
    height: auto;
  }
  .one3d-mi-icon-box {
    padding-left: 16px;
    padding-right: 16px;
  }
  .plt-ios,
  .plt-iphone {
    .one3d-drive-modes-info-content-box {
      padding-bottom: 24px;
    }
    .one3d-drive-button-label-inside {
      padding-bottom: 10px;
    }
    .one3d-drive-button-icon-box-in {
      padding-bottom: 16px;
    }
    .one3d-mi-icon-box {
      padding-bottom: 16px;
    }
    .one3d-interior-exterior {
      .one3d-mi-icon-box {
        padding-bottom: 16px;
      }
    }
    .one3d-back-to-options-box {
      left: 34px;
    }
    .one3d-lhs-hexagon-panel,
    .one3d-lhs-sub-section-panel,
    .one3d-adas-park-mode-info-wrapper {
      left: 40px;
    }
  }
  .one3d-drive-button-icon-box::before {
    bottom: 3px;
  }
  .one3d-drive-button-label {
    padding: 8px 24px;
  }
  .one3d-drive-button-icon-box {
    left: 8px;
    height: 52px;
    bottom: -6px;
  }
  .one3d-drive-button-text {
    font-size: 16px;
  }
  .one3d-drive-button-icon {
    width: 40px;
    height: 40px;
  }
  .one3d-park-button-box {
    .one3d-drive-button-icon {
      width: 30px;
      height: 30px;
      bottom: 2px;
    }
    .one3d-drive-button-icon-box {
      left: -14px;
    }
  }
  .one3d-test-drive-modal {
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }
  .one3d-download-share-modal {
    --max-width: 440px;
    --width: 100%;
    .one3d-test-drive-form-step-inside {
      display: block;
      .one3d-button-primary-text {
        font-size: 14px;
      }
    }
    h3 {
      font-size: 16px;
    }
    p,
    .one3d-test-drive-input-label {
      font-size: 12px;
    }
    .one3d-test-drive-btn-box {
      width: 100%;
    }
    .one3d-copy-url-box {
      .fs-16 {
        font-size: 12px;
      }
      img {
        width: 18px;
      }
    }
  }
  .one3d-rhs-hexagon-panel-ul,
  .one3d-lhs-hexagon-panel-ul,
  .one3d-lhs-sub-section-panel-ul {
    top: 58%;
  }
  .one3d-drive-modes-info-header {
    padding: 6px 40px 6px 10px;
    ion-text {
      font-size: 10px;
    }
  }
  .one3d-drive-modes-info-wrapper {
    max-width: 330px;
  }
  .one3d-drive-modes-info-content-box {
    padding: 12px 16px 20px 16px;
  }
  .plt-iphone .one3d-drive-modes-info-content-box {
    padding: 12px 16px 20px 16px;
  }
  .one3d-drive-modes-info-content {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
  }
  .one3d-drive-mode-info-border {
    top: -4px;
    right: -4px;
    width: 152px;
  }
  .one3d-adas-park-mode-info-wrapper {
    left: 20px;
    max-width: 168px;
  }
  .one3d-adas-park-mode-info-count {
    min-width: 16px;
    font-size: 14px;
    margin-right: 8px;
  }
  .one3d-adas-park-mode-info-text {
    font-size: 10px;
  }
  .one3d-adas-park-mode-info-list-box {
    padding: 10px 10px 10px 4px;
  }
  .one3d-adas-park-mode-info-header {
    padding: 6px 16px 6px 8px;
    ion-text {
      font-size: 8px;
      font-weight: 500;
    }
  }
  .one3d-variant-info-corner {
    top: 76px;
    left: 36px;
    .one3d-variant-name,
    .one3d-variant-price {
      font-size: 12px;
    }
    .one3d-variant-price-text {
      margin-right: 4px;
    }
    img {
      width: 16px;
    }
  }
  .one3d-adas-park-mode-info-li {
    margin-bottom: 4px;
  }
  .one3d-summary-box-inside {
    padding: 4px;
  }
  .one3d-summary-acc-box-top {
    .one3d-summary-acc-col-left {
      ion-text {
        font-size: 10px;
      }
    }
    .one3d-summary-acc-col-right {
      .fs-20 {
        font-size: 14px;
      }
      .fs-14 {
        font-size: 6px;
      }
      span {
        margin-right: 8px;
      }
    }
  }
  .one3d-model-details-middle-container {
    margin: 10px 0 0;
    .mb-6 {
      margin-bottom: 4px;
    }
  }
  .one3d-model-details-box-right {
    .fs-24 {
      font-size: 10px;
      font-weight: 400;
    }
    .fs-18 {
      font-size: 8px;
    }
  }
  .one3d-model-details-bottom-box {
    padding: 24px 16px 10px;
    .one3d-model-details-text-small {
      font-size: 10px;
      margin: 0 0 8px;
    }
    .one3d-model-details-text-large {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .one3d-summary-tabs-bar {
    padding-left: 8px;
    .fs-16 {
      font-size: 12px;
    }
  }
  .one3d-summary-tabs-li {
    padding: 8px;
    .one3d-arrow-left {
      width: 16px;
      left: 0px;
    }
  }
  .one3d-black-button,
  .one3d-white-button {
    min-height: 32px;
  }
  .one3d-cart-text,
  .one3d-white-button .fs-16 {
    font-size: 8px;
    letter-spacing: 0;
  }
  .one3d-cross-icon {
    width: 28px;
  }
  .one3d-main-preloader-sound {
    .fs-20 {
      font-size: 10px;
    }
  }
  .one3d-model-details-modal {
    .one3d-black-button {
      ion-text {
        font-size: 7px;
      }
    }
    .one3d-white-button {
      ion-text {
        font-size: 7px;
      }
    }
  }
  .one3d-model-details-box-icon {
    width: 20px;
  }
  .one3d-model-details-box-col-left {
    left: 4px;
  }
  .one3d-model-details-box-col-right {
    padding: 8px 8px 8px 36px;
  }
  .one3d-preloader-tag-line {
    font-size: 16px;
  }
  .one3d-short-loader-content {
    padding-bottom: 12px;
    .one3d-short-loader-percentage {
      font-size: 20px;
      margin-bottom: 0;
      font-weight: 700;
    }
    .one3d-short-loader-text {
      font-size: 12px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
  .one3d-model-details-main-container {
    .one3d-model-details-box-right {
      margin: 0;
    }
    .pl-16 {
      padding-left: 4px;
    }
    .p-20 {
      padding: 4px;
    }
  }
  .one3d-adas-main-heading {
    top: 16px;
    font-size: 16px;
  }
  .one3d-brand-logo-left-corner {
    left: 48px;
    top: 8px;
    img {
      width: 140px;
    }
  }
  .one3d-brand-name-logo-center {
    top: 24px;
    img {
      width: 300px;
    }
  }
  .one3d-loader-progress-box {
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .one3d-loader-progress:before {
    top: -14px;
    width: 16px;
    height: 16px;
  }
  .one3d-loader-progress:after {
    width: 16px;
    height: 16px;
  }
  .one3d-short-loader-text-carousel {
    height: 30px;
  }
  .one3d-main-preloader-progress-bar-text-carousel {
    height: 30px;
    margin-bottom: -16px;
  }
  .one3d-button-primary-text,
  .one3d-button-secondary-text,
  .one3d-button-tertiary-text {
    font-size: 12px;
  }
  .plt-iphone .one3d-back-to-options-box {
    left: 34px;
  }
  .plt-iphone .one3d-features-story-box {
    padding: 0 10px 0 24px;
  }
  .one3d-features-story-box {
    height: calc(100% - 36px);
  }
  .plt-iphone .one3d-summary-box-inside {
    padding: 10px 10px 10px 24px;
  }
  .plt-iphone .one3d-drive-button-label {
    top: 11px;
  }
  .plt-iphone .one3d-park-button-box,
  .plt-iphone .one3d-drive-button-box {
    bottom: 24px;
  }
  .one3d-description {
    ion-text {
      -moz-animation: newsTickerAnimation 80s linear infinite;
      -webkit-animation: newsTickerAnimation 80s linear infinite;
      animation: newsTickerAnimation 80s linear infinite;
    }
  }
  .one3d-price-reveal-soon-wrap {
    .fs-16 {
      font-size: 12px;
    }
    .fs-20 {
      font-size: 12px;
    }
    .ion-margin-bottom,
    .ion-margin-top {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .one3d-postcode-icon-box {
    display: none;
  }
  .one3d-int-ext-text-half {
    display: none;
  }
  .one3d-full-screen-modal-lhs {
    width: 70%;
  }
  .one3d-full-screen-modal-rhs {
    width: 30%;
  }
  .one3d-features-li {
    margin: 0 0 2px;
    padding: 6px 8px 6px 24px;
  }
  .one3d-feature-desc-info-icon-img {
    width: 14px;
  }
  .one3d-features-listings .one3d-accordion-header {
    --min-height: 36px;
  }
  .one3d-features-listings {
    padding: 6px 0;
  }
  .one3d-interior-exterior .one3d-tooltip-custom {
    left: 32%;
    bottom: 58px;
  }
  .one3d-accessories-tabs-bar {
    .one3d-accessories-tabs-ul {
      .one3d-accessories-tabs-li {
        padding: 4px 4px;
        .fs-16 {
          font-size: 10px;
        }
        &::after {
          height: 1px;
        }
      }
    }
  }
  .one3d-accessories-tabs-content-container {
    .fs-14 {
      font-size: 8px;
    }
    .one3d-add-acc-button {
      border-width: 1px;
      padding: 6px 6px;
      svg {
        width: 8px;
        height: 8px;
        margin-right: 2px;
      }
    }
    .one3d-remove-acc-button {
      padding: 6px 6px;
    }
    .one3d-acc-right-box-top {
      margin-bottom: 2px;
    }
  }
  .one3d-black-button .one3d-cart-icon {
    width: 16px;
  }
  .one3d-footer-description {
    padding: 4px 8px;
    .fs-8 {
      font-size: 6px;
    }
  }
  .one3d-accessories-tabs-panel-inside {
    height: calc(100% - 68px);
  }
  .one3d-acc-box {
    margin-bottom: 4px;
  }
  .one3d-accessories-tabs-content-container {
    height: calc(100% - 28px);
  }
  .one3d-footer-description {
    .fs-10 {
      font-size: 6px;
      line-height: 1.3;
    }
  }
  .one3d-more-content {
    padding: 8px 0px;
  }
  .one3d-black-button {
    .one3d-cart-count {
      font-size: 8px;
    }
  }
  .one3d-added-accessories-box-main {
    .one3d-summary-box-inside {
      height: calc(100% - 40px);
    }
    .one3d-acc-left-box {
      img {
        height: 72px;
      }
    }
    .one3d-acc-right-box-top {
      .fs-14 {
        font-size: 10px;
      }
    }
    .one3d-delete-red-icon {
      width: 12px;
    }
    .one3d-acc-right-box {
      padding: 8px;
    }
  }
  .one3d-added-acc-heading {
    margin: 0;
    .fs-18 {
      font-size: 14px;
    }
  }
  .one3d-car-interior-view-active {
    .one3d-lhs-hexagon-panel,
    .one3d-lhs-sub-section-panel {
      bottom: 0;
    }
    .one3d-lhs-hexagon-panel-ul,
    .one3d-lhs-sub-section-panel-ul {
      top: 46%;
    }
  }
  .plt-iphone .one3d-feature-description-container {
    bottom: 24px;
  }
  .one3d-feature-description-container {
    max-width: 80%;
    min-width: 200px;
    .one3d-adas-park-mode-info-list-box {
      padding: 10px;
    }
    .one3d-adas-park-mode-info-header {
      ion-text {
        font-size: 10px;
      }
    }
    .one3d-adas-park-mode-info-text {
      font-size: 12px;
      font-weight: 400;
    }
    .one3d-feature-description-close {
      top: -30px;
      img {
        width: 24px;
      }
    }
  }
  .one3d-seating-rows-options {
    .one3d-colors-list-box {
      .one3d-seating-row-icon {
        width: 30px;
      }
    }
  }
  .one3d-add-accessories-now-text {
    padding: 0 8vw;
    font-size: 14px;
  }
  .one3d-add-accessories-now-btn {
    padding: 4px 8px;
    font-size: 12px;
    svg {
      width: 14px;
    }
  }
  .one3d-offers-box-main {
    .one3d-available-offers {
      margin-bottom: 0;
    }
    .fs-16 {
      font-size: 12px;
    }
    .fs-14 {
      font-size: 10px;
    }
    .fs-24 {
      font-size: 14px;
    }
  }
  .one3d-offer-description {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .one3d-feature-description-container .one3d-drive-mode-info-border {
    width: 168px;
  }
  .one3d-lhs-hexagon-panel-sub-text {
    font-size: 8px;
  }
  .one3d-shave-share-modal-box {
    padding: 20px;
    .one3d-test-drive-form-step-inside {
      align-items: flex-start;
      flex-direction: column;
    }
    .one3d-test-drive-btn-box {
      width: 100%;
    }
    .one3d-copy-url-text {
      width: calc(100% - 90px);
    }
    .one3d-copy-url-btn {
      width: 90px;
      .fs-16 {
        font-size: 10px;
      }
    }
  }
}
@media only screen and (max-width: 991px) and (orientation: portrait) {
  .one3d-rhs-hexagon-panel-li,
  .one3d-lhs-hexagon-panel-li,
  .one3d-lhs-sub-section-panel-li {
    margin: 4vw 0;
  }
  .one3d-close-test-drive-form {
    right: 0;
    top: -36px;
  }
  .one3d-postcode-modal-box {
    min-width: 310px;
  }
  .one3d-test-drive-form-step-inside {
    flex-direction: column;
  }
  .one3d-test-drive-input-field {
    width: 100%;
    flex: 0 0 auto;
    padding-right: 0;
    margin-bottom: 0px !important;
  }
  .one3d-test-drive-btn-box {
    width: 100%;
    margin-top: 12px;
  }
  .one3d-test-drive-input-country-code {
    height: 36px;
  }
  .one3d-test-drive-ion-col-left {
    padding-left: 0;
  }
  .one3d-test-drive-ion-col-right {
    padding-right: 0;
  }
  .one3d-email-description-wrapper-main {
    margin-top: 12px;
  }
  .one3d-email-description-wrap p,
  .one3d-checkbox-email-container .new-label-checkbox-container .content {
    font-size: 8px !important;
  }
  .one3d-email-description-extra-text {
    overflow: auto;
    padding-right: 12px;
    max-height: 40px;
  }
  .one3d-test-drive-modal::part(content) {
    padding: 10px;
    overflow: visible;
  }
}
@media only screen and (max-width: 991px) and (orientation: landscape) {
  .one3d-rhs-hexagon-panel-li,
  .one3d-lhs-hexagon-panel-li,
  .one3d-lhs-sub-section-panel-li {
    margin: 3vh 0;
  }
  .one3d-email-description-desktop {
    display: none;
  }
  .one3d-email-description-mobile {
    display: none;
  }
  .one3d-email-description-mobile-landscape {
    display: block;
    margin-top: 8px;
  }
  .one3d-test-drive-ion-col-left {
    padding-left: 0;
    padding-bottom: 0;
  }
  .one3d-test-drive-ion-col-right {
    padding-bottom: 0;
  }
  .one3d-test-drive-input {
    min-height: 40px !important;
  }
  .one3d-test-drive-input-field,
  .one3d-test-drive-btn-box {
    margin-bottom: 0px !important;
  }
  .one3d-email-description-extra-text {
    max-height: 26px;
    overflow: auto;
    padding-right: 12px;
  }
  .one3d-email-description-wrap p,
  .one3d-checkbox-email-container .new-label-checkbox-container .content {
    font-size: 8px !important;
  }
  .one3d-test-drive-modal-box {
    max-width: 700px !important;
  }
  .one3d-test-drive-input-country-code {
    height: 40px;
  }
  .one3d-shave-share-modal-box {
    max-width: 500px !important;
  }
}
//***  ====================================  mobile screen ends here
//**   ==================================== iPad code starts here
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .one3d-rhs-hexagon-panel-ul
    .one3d-rhs-hexagon-panel-li
    .one3d-rhs-hexagon-panel-text,
  .one3d-lhs-hexagon-panel-ul
    .one3d-lhs-hexagon-panel-li
    .one3d-lhs-hexagon-panel-text,
  .one3d-lhs-sub-section-panel-ul
    .one3d-lhs-sub-section-panel-li
    .one3d-lhs-sub-section-panel-text {
    font-size: 20px;
  }
  .one3d-button-primary-text,
  .one3d-discover-more-box .one3d-button-primary .one3d-button-primary-text,
  .one3d-button-secondary-text,
  .one3d-button-tertiary-text {
    font-size: 16px;
    padding: 10px 20px;
  }
  .one3d-drive-modes-info-box {
    .fs-16 {
      font-size: 14px;
    }
    .fs-20 {
      font-size: 16px;
    }
  }
  .one3d-button-sound img,
  .one3d-button-muted img {
    width: 24px;
    height: 24px;
  }
  .one3d-colors-list-item {
    width: 40px;
    height: 40px;
  }
  .one3d-colors-list-box {
    height: 40px;
  }
  .one3d-color-roof {
    top: -0.3vw;
    right: -1vw;
  }
  .one3d-color-name-text {
    font-size: 16px;
  }
  .one3d-colors-list-li {
    margin: 0 10px;
  }
  .one3d-colors-options {
    bottom: 20px;
  }
  .one3d-variant-info {
    .fs-18 {
      font-size: 24px;
    }
  }
  .one3d-model-details-middle-container {
    .fs-20 {
      font-size: 16px;
    }
    .fs-16 {
      font-size: 14px;
    }
  }
  .one3d-model-details-box-col-right {
    padding: 20px 20px 20px 48px;
  }
  .one3d-model-details-box-col-left {
    left: 8px;
    img {
      width: 30px;
    }
  }
  .one3d-model-details-bottom-box {
    .fs-32 {
      font-size: 22px;
    }
    .fs-16 {
      font-size: 14px;
    }
  }
}
//**   ==================================== iPad code ends here
.one3d-disabled-tab {
  opacity: 0.4;
  pointer-events: none;
}
//***   ==================================== desktop screen greater than 1600px starts here
@media only screen and (min-width: 1600px) {
  .one3d-postcode-button {
    right: 3.2vw;
  }
  .one3d-color-name {
    top: -2.4vw;
  }
  .one3d-brand-logo-left-corner {
    left: 4vw;
    img {
      width: 16vw;
    }
  }
  .one3d-tooltip-custom-text {
    font-size: 0.7vw;
  }
  .one3d-tooltip-custom-box {
    padding: 0.4vw 0.6vw;
  }
  .one3d-drive-modes-info-box {
    .fs-16 {
      font-size: 1vw;
    }
    .one3d-drive-modes-info-content {
      font-size: 1vw;
    }
  }
  .one3d-drive-modes-info-wrapper {
    max-width: 30vw;
  }
  .one3d-variant-info-corner {
    left: 4vw;
    top: 3.6vw;
    .fs-18 {
      font-size: 1vw;
    }
    .fs-16 {
      font-size: 0.8vw;
    }
    .fs-14 {
      font-size: 0.7vw;
    }
    .one3d-info-icon {
      width: 1vw;
    }
    .one3d-price-disclaimer-top {
      max-width: 18vw;
    }
  }
  .one3d-accordion-group {
    .fs-14 {
      font-size: 24px;
    }
    .fs-10 {
      font-size: 20px;
    }
  }
  .one3d-features-li {
    padding: 8px 8px 8px 32px;
  }
}
//***   ==================================== desktop screen greater than 1400px starts here
@media only screen and (min-width: 1400px) {
  .one3d-tutorial {
    transform: translate(-50%, 0) scale(1.3);
  }
}
// UI Animation Code Starts Here
// .one3d-rhs-hexagon-panel,
// .one3d-full-screen-modal-rhs {
//   transform: translate(100vw, 0) !important;
// }
// .one3d-lhs-hexagon-panel,
// .one3d-lhs-sub-section-panel,
// .one3d-adas-park-mode-info-wrapper {
//   transform: translate(-100vw, 0) !important;
// }
// .one3d-brand-logo-left-corner {
//   transform: translate(0, -100vh) !important;
// }
// .one3d-adas-main-heading {
//   opacity: 0 !important;
// }
// .one3d-test-drive-speaker-box,
// .one3d-colors-options,
// .one3d-discover-more-box,
// .one3d-description,
// .one3d-drive-modes-info-wrapper,
// .one3d-drive-button-box,
// .one3d-mi-panel,
// .one3d-btn-group-right,
// .one3d-full-screen-modal-lhs {
//   transform: translate(0, 100vh) !important;
// }
// .one3d-colors-options-show,
// .one3d-discover-more-box-show,
// .one3d-rhs-hexagon-panel-show,
// .one3d-lhs-hexagon-panel-show,
// .one3d-test-drive-speaker-box-show,
// .one3d-brand-logo-left-corner-show,
// .one3d-lhs-sub-section-panel-show,
// .one3d-description-show,
// .one3d-drive-modes-info-wrapper-show,
// .one3d-drive-button-box-show,
// .one3d-adas-park-mode-info-wrapper-show,
// .one3d-adas-main-heading-show,
// .one3d-mi-panel-show,
// .one3d-btn-group-right-show,
// .one3d-full-screen-modal-rhs-show,
// .one3d-full-screen-modal-lhs-show {
//   transform: translate(0, 0) !important;
// }
// .one3d-adas-main-heading-show {
//   opacity: 1 !important;
// }
// UI Animation Code Ends Here
